.sun-auth {
    background-color: var(--chakra-colors-orange-200);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    top: 60px;
    right: 100px;
    position: absolute;
    box-shadow: 0px 0px 10px 5px var(--chakra-colors-orange-200);
}

.cloud-group-auth {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
}

.cloud-auth {
    background-color: #fafafa;
    width: 200px;
    height: 60px;
    border-radius: 50px;
    left: 24px;
    top: 72px;
    animation: clouds-auth 10s linear infinite;
    position: absolute;
}

.tree-group-auth {
    width: 100%;
    height: 360px;
    bottom: 0px;
    left: 0;
    overflow: hidden;
    position: absolute;
}

.tree-auth {
    z-index: 1;
    bottom: 10px;
    width: 5px;
    height: 50px;
    position: absolute;
}
.tree-top-auth-small {
    background-color: #4da85b;
    border-radius: 100%;
    top: -30px;
    width: 40px;
    height: 40px;
    left: -20px;
    position: absolute;
}
.tree-top-auth-small-center {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 5%;
    right: 5%;
    background-color: #a7cc00;
    border-radius: 100%;
    position: absolute;
}
.tree-top-auth-big-top {
    top: -120px;
    width: 46px;
    height: 40px;
    left: -18px;
    position: absolute;
    border-radius: 50px;
    background-color: #a7cc00;
}
.tree-top-auth-big-middle {
    top: -95px;
    width: 90px;
    height: 55px;
    left: -40px;
    background-color: #a7cc00;
    border-radius: 50px;
    position: absolute;
}
.tree-top-auth-big-bottom {
    top: -60px;
    width: 130px;
    height: 65px;
    left: -60px;
    border-radius: 50px;
    background-color: #a7cc00;
    position: absolute;
}
.tree-top-auth-big-shadow {
    height: 100%;
    width: 50%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: #4da85b;
}

.trunk-auth {
    background-color: #c2653c;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
}

.base-auth {
    width: 100vw;
    height: 20px;
    background-color: #73bf7f;
    bottom: 0px;
    position: absolute;
}

.bush-group-auth {
    width: calc(100% + 30px);
    height: 40px;
    bottom: 20px;
    left: -30px;
    overflow: hidden;
    position: absolute;
}

.bush-auth {
    z-index: 100;
    width: 90px;
    height: 40px;
    bottom: 0px;
    left: 0;
    background-color: #73bf7f;
    border-radius: 50px 50px 0 0;
    position: absolute;
}

@keyframes clouds-auth {
    50% {
        transform: translateX(60px);
    }
    100% {
        transform: translateX(0);
    }
}
