body {
    overscroll-behavior: none;
}

.notes-editor {
    padding: 2px 7px;
    border-radius: 8px;
    border: 1px solid transparent;
    outline: none;
}
.notes-editor:focus {
    background-color: rgba(118, 61, 225, 0.1);
}

.description-link {
    cursor: pointer;
    color: #06b7db;
    text-decoration: underline;
}

.ProseMirror p.is-editor-empty:first-child::before {
    height: 0;
    float: left;
    color: #99a0c2; /*color is grey.500*/
    pointer-events: none;
    content: attr(data-placeholder);
}

.react-datepicker__input-container {
    display: flex;
}
.react-datepicker__day {
    font-size: 16px;
    color: #41486c !important;
    font-weight: 500;
    margin: 4px !important;
}
.react-datepicker__day:hover {
    background-color: #ffb3b3 !important;
}

.react-datepicker__header {
    font-size: 16px;
    background-color: #ff7979 !important;
    color: #41486c !important;
    border-top-right-radius: 14px !important;
    border-top-left-radius: 14px !important;
}

.react-datepicker__current-month {
    color: #41486c !important;
}

.react-datepicker__day-names {
    font-weight: 500;
    font-size: 16px;
    color: #41486c !important;
}
.react-datepicker__day-name {
    margin: 4px !important;
    color: #41486c !important;
}

.react-datepicker {
    border-radius: 16px !important;
    border: 2px solid #41486c !important;
    box-shadow: 0px 4px 8px 0 rgba(56, 96, 165, 0.15);
}

.react-datepicker__day--selected {
    color: #fafafa !important;
    background-color: #ff5757 !important;
}

.react-datepicker__navigation {
    padding-top: 16px !important;
}

.react-datepicker__navigation--next {
    border: #3860b8 !important;
}

.react-datepicker__day .react-datepicker__day--keyboard-selected {
    border-color: #522ed6 !important;
}

.react-datepicker__triangle {
    border-bottom-color: #41486c !important;
}
.react-datepicker__triangle::before {
    border-bottom-color: #41486c !important;
}
.react-datepicker__triangle::after {
    border-bottom-color: #41486c !important;
}

.react-datepicker__day--today {
    background-color: #ff8c8c !important;
}

.react-datepicker__navigation-icon::before {
    border-color: #636da1 !important;
}

.llamaChip:hover > div {
    visibility: visible;
    opacity: 1;
    width: 100%;
    padding-left: 8px;
    margin-right: -12px;
    transition: all 200ms ease;
    transition-property: opacity, padding;
}

.react-datepicker-wrapper {
    width: unset !important;
}
/* when */
.when .react-datepicker__day {
    font-size: 16px;
    color: #41486c !important;
    font-weight: 500;
    margin: 4px !important;
}
.when .react-datepicker__day:hover {
    background-color: #d1e8e2 !important;
}

.when .react-datepicker__header {
    font-size: 16px;
    background-color: #61c6ad !important;
    color: #41486c !important;
    border-top-right-radius: 14px !important;
    border-top-left-radius: 14px !important;
}

.when .react-datepicker__current-month {
    color: #41486c !important;
}

.when .react-datepicker__day-names {
    font-weight: 500;
    font-size: 16px;
    color: #41486c !important;
}
.when .react-datepicker__day-name {
    margin: 4px !important;
    color: #41486c !important;
}

.when .react-datepicker {
    border-radius: 16px !important;
    border: 2px solid #41486c !important;
    box-shadow: 0px 4px 8px 0 rgba(56, 96, 165, 0.15);
}

.when .react-datepicker__day--selected {
    color: #fafafa !important;
    background-color: #46bc9f !important;
}

.when .react-datepicker__navigation {
    padding-top: 16px !important;
}

.when .react-datepicker__navigation--next {
    border: #3860b8 !important;
}

.when .react-datepicker__day .react-datepicker__day--keyboard-selected {
    border-color: #522ed6 !important;
}

.react-datepicker__triangle {
    border-bottom-color: #41486c !important;
}
.when .react-datepicker__triangle::before {
    border-bottom-color: #41486c !important;
}
.when .react-datepicker__triangle::after {
    border-bottom-color: #41486c !important;
}

.when .react-datepicker__day--today {
    background-color: #a2d1c5 !important;
}

.when .react-datepicker__navigation-icon::before {
    border-color: #636da1 !important;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.16s;
}

.cl-internal-b3fm6y {
    background-color: var(--chakra-colors-purple-300);
}

.cl-alert {
    display: none;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes strike {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
.strike {
    position: relative;
}
.strike::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #442aa2;
    animation-name: strike;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    border-radius: 8px;
}

@keyframes levelStrike {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
.levelStrike {
    position: relative;
}
.levelStrike::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #515a87;
    animation-name: strike;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.bouncey-boi {
    animation-name: bounce;
}

.new-golden-llama {
    animation: flame 1s 1;
    transform-origin: bottom;
}

.flame {
    animation: flame 1s 3;
    transform-origin: bottom;
}

@keyframes flame {
    0% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(1.5) scaleX(1.5);
    }
    100% {
        transform: scaleY(1);
    }
}

.llama-task-name {
    border-width: 0px;
    color: #46bc9f;
}

@-webkit-keyframes borderBlink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: #ff1f1f;
    }
}
@keyframes borderBlink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: #ff1f1f;
    }
}
.borderBlink {
    border: 2px solid #ff1f1f;
    -webkit-animation: borderBlink 1s step-end infinite;
    animation: borderBlink 1s step-end infinite;
}

.loading-llama {
    transform-origin: center;
    animation: loading-llama 3s linear infinite;
}

@keyframes loading-llama {
    0% {
        transform: translate(0%, 0%);
    }
    5% {
        transform: translate(0%, -10%);
    }
    10% {
        transform: translate(0%, 0%);
    }
    15% {
        transform: translate(0%, -10%);
    }
    20% {
        transform: translate(0%, 0%);
    }
    25% {
        transform: translate(0%, -15%) rotate(-72deg);
    }
    30% {
        transform: translate(0%, -15%) rotate(-144deg);
    }
    35% {
        transform: translate(0%, -15%) rotate(-216deg);
    }
    40% {
        transform: translate(0%, -15%) rotate(-288deg);
    }
    45% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    50% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    55% {
        transform: translate(0%, -10%) rotate(-360deg);
    }
    60% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    65% {
        transform: translate(0%, -10%) rotate(-360deg);
    }
    70% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    75% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    80% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    85% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    90% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    95% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
    100% {
        transform: translate(0%, 0%) rotate(-360deg);
    }
}

.gamer-text {
    font-family: 'Press Start 2P';
}

.loading {
    color: #0000;
    background: linear-gradient(90deg, #3d20a3 calc(50% + 0.5ch), #fff 0)
        right/calc(200% + 1ch) 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: c7 3s infinite steps(27);
}
@keyframes c7 {
    to {
        background-position: left;
    }
}
