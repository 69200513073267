.flex {
    display: flex;
}

.alpaca__container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    transform: rotateY(180deg);
}
.alpaca-colored {
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
}
.alpaca__top,
.alpaca__btm {
    height: 50%;
    width: 100%;
    position: relative;
}
.alpaca__top {
    height: 50%;
    justify-content: space-between;
}

.head {
    width: 40%;
    position: relative;
    align-items: flex-end;
}
.head__ears-colored {
    width: 100%;
    height: 28%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
}
.head__ears-colored div {
    width: 25%;
    height: 100%;
    width: 25%;
    position: relative;
}
.head__ears-colored div::after {
    content: '';
    width: 50%;
    height: 55%;
    position: absolute;
    bottom: 5%;
}
.head__ears-colored div:first-child,
.head__ears-colored div:first-child::after {
    border-radius: 100% 0 0 100%/50% 0 0 50%;
}
.head__ears-colored div:first-child {
    transform-origin: 100% 100%;
    transform: rotate(0) translate(0%, 15%);
    animation: twitch-ear-left 10s 3s infinite ease-in-out;
}
.head__ears-colored div:first-child::after {
    right: 20%;
    bottom: 20%;
}
.head__ears-colored div:last-child,
.head__ears-colored div:last-child::after {
    border-radius: 0 100% 100% 0/0 50% 50% 0;
}
.head__ears-colored div:last-child {
    transform-origin: 0 100%;
    transform: rotate(0deg) translate(0%, 15%);
    animation: twitch-ear-right 10s 3.1s infinite ease-in-out;
}
.head__ears-colored div:last-child::after {
    left: 20%;
    bottom: 20%;
}

.neck__hair {
    height: 25%;
    width: 100%;
    bottom: 0px;
    position: absolute;
}
.hair-container {
    height: 100%;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
}
.curl-colored {
    height: 65%;
    width: 40%;
    border-radius: 50%;
}
.head__face-neck-colored {
    width: 100%;
    height: 75%;
    border-radius: 50% 50% 0 0/30% 30% 0 0;
}
.head__face-neck-colored .face {
    position: relative;
    width: 100%;
    height: 40%;
    flex-direction: column;
    align-items: center;
    margin-top: 36%;
}
.head__face-neck-colored .face::before {
    content: '';
    width: 70%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.head__face-neck-colored .eyes,
.head__face-neck-colored .cheeks,
.head__face-neck-colored .nose-colored,
.head__face-neck-colored .mouth-colored {
    z-index: 10;
}
.head__face-neck-colored .eyes {
    width: 70%;
    height: 45%;
    justify-content: space-between;
    margin-top: -5%;
}
.head__face-neck-colored .eyes div {
    width: 39%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.head__face-neck-colored .eyes div::after {
    content: '';
    width: 45%;
    height: 45%;
    position: absolute;
    background-color: #4e9cd3;
    border-radius: 50%;
    animation: blink 5s infinite ease-in-out;
}
.sunnies {
    height: 60%;
    width: 100%;
    z-index: 15;
    bottom: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
}
.duct-tape-left {
    height: 27%;
    width: 45%;
    position: absolute;
    left: 0;
    z-index: 9999999;
    border-radius: 20%;
    background-color: #3a3636;
}
.duct-tape-right {
    height: 27%;
    width: 45%;
    position: absolute;
    right: 0;
    z-index: 9999999;
    border-radius: 20%;
    background-color: #3a3636;
}
.stem-left {
    height: 27%;
    width: 11.5%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 20% 0 0 20%;
}
.stem-right {
    height: 27%;
    width: 11.5%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 0 20% 20% 0;
}
.frame-left-colored {
    height: 100%;
    width: 50%;
    z-index: 16;
    border-radius: 50% 0 60% 50%/30% 0 50% 50%;
}
.lens-left-colored {
    left: 5%;
    width: 40%;
    height: 80%;
    z-index: 17;
    position: absolute;
    border-radius: 50% 0 60% 50%/30% 0 50% 50%;
    background: linear-gradient(
        to bottom right,
        #f6ff00,
        #f6ff00 15%,
        #d1cc88 35%,
        #d1cc88 35%,
        #f6ff00 55%,
        #f6ff00
    );
}
.bridge-colored {
    align-self: flex-start;
    height: 60%;
    width: 2%;
    background-color: #f2c600;
}
.bridge-lens-colored {
    position: absolute;
    margin-top: 3.6%;
    height: 42%;
    width: 10%;
    z-index: 17;
    align-self: flex-start;
}
.frame-right-colored {
    height: 100%;
    width: 50%;
    z-index: 16;
    border-radius: 0 50% 50% 60%/0 30% 50% 50%;
}
.lens-right-colored {
    right: 5%;
    width: 40%;
    height: 80%;
    z-index: 17;
    position: absolute;
    border-radius: 0 50% 50% 60%/0 30% 50% 50%;
}
.head__face-neck-colored .cheeks {
    width: 90%;
    height: 25%;
    justify-content: space-between;
    margin-top: 3%;
}
.head__face-neck-colored .cheeks div {
    width: 18%;
    height: 100%;
    background-color: #e87e70;
    border-radius: 50%;
}
.head__face-neck-colored .snout {
    position: absolute;
    width: 77%;
    height: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: -4%;
}
.head__face-neck-colored .snout::before {
    content: '';
    width: 70%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
}
.head__face-neck-colored .nose-colored {
    width: 20%;
    height: 15%;
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    margin-top: 5%;
    z-index: 999999;
}
.head__face-neck-colored .mouth-colored {
    width: 50%;
    height: 25%;
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    margin-top: 5%;
    z-index: 999999;
    animation: smile 4s 0.5s infinite ease-in-out;
    transition: 300ms ease-in-out;
}

.tail-colored {
    border-radius: 0 100% 100% 0/0 50% 50% 0;
    align-self: flex-end;
    transform-origin: 10% 90%;
    position: absolute;
    right: 1%;
    top: -15%;
    transform: rotate(0);
    animation: flick-tail 8s 1.5s infinite ease-in-out;
}
.crumb-container {
    height: 100%;
    width: 80%;
    position: absolute;
    top: 10%;
    z-index: 9999999999;
}
.crumb {
    visibility: hidden;
    height: 8px;
    width: 8px;
    background-color: var(--chakra-colors-orange-200);
    border-radius: 4px;
    position: absolute;
}
.crumb-flying-top-right {
    visibility: visible;
    animation: crumb-flying-top-right 0.5s linear;
}
.crumb-flying-bottom-right {
    visibility: visible;
    animation: crumb-flying-bottom-right 0.5s linear;
}
.crumb-flying-top-left {
    visibility: visible;
    animation: crumb-flying-top-left 0.5s linear;
}
.crumb-flying-bottom-left {
    visibility: visible;
    animation: crumb-flying-bottom-left 0.5s linear;
}

.body-colored {
    width: 90%;
    height: 55%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 10% 50% 50%/0 20% 60% 60%;
    align-items: center;
    justify-content: flex-end;
}
.body_hair {
    width: 100%;
    height: 55%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 10% 50% 50%/0 20% 60% 60%;
    align-items: center;
    justify-content: flex-end;
}
.body-colored .spots,
.body-colored .spots::after,
.body-colored .spots::before {
    border-radius: 50%;
}
.body-colored .spots {
    width: 0;
    height: 0;
    position: relative;
    right: 16%;
    bottom: 13%;
    border-top: 6.5vmin solid #acdfd0;
    border-right: 2.5vmin solid transparent;
    border-left: 2.5vmin solid transparent;
}
.body-colored .spots::before,
.body-colored .spots::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    background-color: transparent;
    border-top: 5vmin solid #acdfd0;
    border-right: 2vmin solid transparent;
    border-left: 2vmin solid transparent;
    transform-origin: 50% 0;
}
.body-colored .spots::before {
    left: -130%;
    transform: rotate(55deg) translate3d(0, -90%, 0);
}
.body-colored .spots::after {
    right: -130%;
    transform: rotate(-55deg) translate3d(0, -90%, 0);
}

.legs {
    width: 100%;
    height: 100%;
    justify-content: space-between;
}
.legs__front-colored,
.legs__back-colored {
    width: 40%;
    height: 100%;
    position: relative;
}
.legs__front-colored div,
.legs__back-colored div {
    width: 55%;
    height: 90%;
    position: absolute;
}
.legs__front-colored div::after,
.legs__back-colored div::after {
    content: '';
    width: 35%;
    height: 50%;
    position: absolute;
    bottom: -20%;
}
.legs__front-colored div {
    border-radius: 0 0 100% 100%/0 0 25% 25%;
}
.legs__front-colored div:first-child::after,
.legs__front-colored div:last-child::after {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}
.legs__front-colored div:last-child {
    right: 0;
}
.legs__back-colored div {
    border-radius: 0 40% 0 100%/0 30% 0 30%;
}
.legs__back-colored div:last-child,
.legs__back-colored div:last-child::after,
.legs__back-colored div:first-child::after {
    right: 0;
}
.grass {
    width: 100%;
    height: 10%;
    background-color: #6fc66c;
    position: absolute;
    bottom: 0px;
    z-index: -999;
}
.speech-bubble {
    left: 10px;
    width: 240px;
    bottom: 145px;
    position: absolute;
}
.curly-q {
    width: 60px;
    height: 32px;
    position: absolute;
    left: 32px;
    bottom: 0;
    border-bottom-left-radius: 28px;
    border-left: 20px solid var(--chakra-colors-greenFaded-100);
    z-index: 1;
}
.speech-box {
    width: 100%;
    bottom: 15px;
    padding: 16px;
    margin-left: 4px;
    border-radius: 16px;
    position: absolute;
    box-shadow: 5px 0px 16px 0 rgba(56, 96, 165, 0.25);
    background-color: var(--chakra-colors-greenFaded-100);
}

.bounce-neck {
    animation: bounce-neck 1s linear;
}
.bounce-llama {
    animation: bounce-llama 1s linear;
}

@keyframes bounce-llama {
    0% {
        margin-bottom: 0%;
    }
    25% {
        margin-bottom: 9%;
    }
    50% {
        margin-bottom: 0%;
    }
    75% {
        margin-bottom: 9%;
    }
    100% {
        margin-bottom: 0%;
    }
}

@keyframes bounce-neck {
    0% {
        height: 0%;
    }
    25% {
        height: 5%;
    }
    50% {
        height: 0%;
    }
    75% {
        height: 5%;
    }
    100% {
        height: 0%;
    }
}

@keyframes grow-neck {
    0% {
        height: 0px;
    }
    50% {
        height: calc(100vh * 0.4);
    }
    100% {
        height: 0px;
    }
}

@keyframes drop-sunnies {
    0% {
        transform: translate(0%, -700%);
    }
    50% {
        transform: translate(0%, -90%);
    }
    100% {
        transform: translate(0%, -90%);
    }
}

@keyframes blink {
    0% {
        height: 45%;
    }
    2% {
        height: 0%;
    }
    4% {
        height: 45%;
    }
    6% {
        height: 0%;
    }
    8% {
        height: 45%;
    }
    100% {
        height: 45%;
    }
}
@keyframes flick-tail {
    0% {
        transform: rotate(0);
    }
    2% {
        transform: rotate(45deg);
    }
    6% {
        transform: rotate(-10deg);
    }
    9% {
        transform: rotate(45deg);
    }
    12% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}
@keyframes twitch-ear-left {
    0% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    5% {
        transform: rotate(-45deg) translate(0%, 15%);
    }
    8% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    100% {
        transform: rotate(0deg) translate(0%, 15%);
    }
}
@keyframes twitch-ear-right {
    0% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    5% {
        transform: rotate(45deg) translate(0%, 15%);
    }
    8% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    51% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    53% {
        transform: rotate(45deg) translate(0%, 15%);
    }
    55% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    100% {
        transform: rotate(0deg) translate(0%, 15%);
    }
}
@keyframes smile {
    0% {
        height: 25%;
    }
    70% {
        height: 25%;
    }
    75% {
        height: 40%;
    }
    80% {
        height: 40%;
    }
    85% {
        height: 5%;
    }
    90% {
        height: 20%;
    }
    95% {
        height: 5%;
    }
    100% {
        height: 25%;
    }
}
@keyframes openmouth {
    0% {
        width: 50%;
        height: 25%;
    }
    100% {
        width: 90%;
        height: 80%;
    }
}
@keyframes monch {
    0% {
        width: 90%;
        height: 80%;
    }
    12% {
        width: 50%;
        height: 5%;
    }
    25% {
        height: 40%;
    }
    37% {
        height: 5%;
    }
    50% {
        height: 20%;
    }
    62% {
        height: 5%;
    }
    75% {
        height: 20%;
    }
    87% {
        height: 5%;
    }
    100% {
        height: 25%;
    }
}
@keyframes crumb-flying-top-right {
    0% {
        left: 0%;
    }
    25% {
        left: -100%;
        top: -200%;
    }
    50% {
        left: -200%;
        top: 0%;
    }
    100% {
        left: -300%;
        top: 300%;
    }
}

@keyframes crumb-flying-bottom-right {
    0% {
        left: 0%;
    }
    25% {
        left: -100%;
        bottom: 150%;
    }
    50% {
        left: -200%;
        bottom: 0%;
    }
    100% {
        left: -300%;
        bottom: -500%;
    }
}

@keyframes crumb-flying-top-left {
    0% {
        right: 0%;
    }
    25% {
        right: -100%;
        top: -200%;
    }
    50% {
        right: -200%;
        top: 0%;
    }
    100% {
        right: -300%;
        top: 300%;
    }
}

@keyframes crumb-flying-bottom-left {
    0% {
        right: 0%;
    }
    25% {
        right: -100%;
        bottom: 150%;
    }
    50% {
        right: -200%;
        bottom: 0%;
    }
    100% {
        right: -300%;
        bottom: -500%;
    }
}
