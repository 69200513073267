.bigContainer {
    --bgd-color: #d2eee8;
    --light: #9dd7fb;
    --medium: #7ac0cd;
    --dark: #56a0c8;
    --bush: #fafafa;
    --trunk: #c2653c;
    --trunk2: #9d5d5d;
    --cloud: #fafafa;
    --sun: transparent;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: var(--bgd-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 440px;
    width: 300px;
    min-width: 300px;
    transition: 4s ease background;
    height: 100%;
}
.container {
    position: relative;
    width: 300px;
    height: 450px;
    transition: 4s ease background;
    margin-top: auto;
}
.sun {
    background-color: var(--sun);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    bottom: 40vh;
    left: 210px;
    transition: 4s ease background;
    position: absolute;
}

.cloud-group {
    width: 100%;
    height: 50vh;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: 4s ease background;
    position: absolute;
}
.cloud {
    background-color: var(--cloud);
    width: 80px;
    height: 30px;
    border-radius: 50px;
    left: 24px;
    top: 72px;
    animation: clouds 10s linear infinite;
    transition: 4s ease background;
    position: absolute;
}

.cloud2 {
    left: 88px;
    top: 108px;
    width: 90px;
    transition: 4s ease background;
    position: absolute;
}

@keyframes clouds {
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0);
    }
}
.weather-container {
    width: 100%;
    height: 400px;
    transition: 4s ease background;
    position: absolute;
}
.snow {
    opacity: 0;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    transition: 2s ease all;
    animation: snow 5s linear infinite;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(1) {
    top: 14px;
    left: 51px;
    animation-delay: 6s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(3) {
    top: 26px;
    left: 9px;
    animation-delay: 1s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(5) {
    top: 23px;
    left: 32px;
    animation-delay: 3s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(7) {
    top: 14px;
    left: 21px;
    animation-delay: 6s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(9) {
    top: 20px;
    left: 29px;
    animation-delay: 4s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(11) {
    top: 23px;
    left: 25px;
    animation-delay: 3s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(13) {
    top: 13px;
    left: 20px;
    animation-delay: 5s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(15) {
    top: 2px;
    left: 37px;
    animation-delay: 3s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(17) {
    top: 29px;
    left: 40px;
    animation-delay: 4s;
    transition: 4s ease background;
    position: absolute;
}
.snow:nth-of-type(19) {
    top: 24px;
    left: 26px;
    animation-delay: 7s;
    transition: 4s ease background;
    position: absolute;
}
.rain {
    opacity: 0;
    display: none;
    background-color: #5379ca;
    width: 4px;
    height: 8px;
    transform: rotate(-30deg);
    border-color: #5379ca;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: 2s ease all;
    animation: drops 1s linear infinite;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(1) {
    top: 29px;
    left: 69px;
    animation-delay: 0.1s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(3) {
    top: 12px;
    left: 70px;
    animation-delay: 0.3s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(5) {
    top: 5px;
    left: 55px;
    animation-delay: 0.5s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(7) {
    top: 6px;
    left: 58px;
    animation-delay: 0.7s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(9) {
    top: 15px;
    left: 23px;
    animation-delay: 0.9s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(11) {
    top: 30px;
    left: 58px;
    animation-delay: 1.1s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(13) {
    top: 28px;
    left: 47px;
    animation-delay: 1.3s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(15) {
    top: 9px;
    left: 6px;
    animation-delay: 1.5s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(17) {
    top: 19px;
    left: 6px;
    animation-delay: 1.7s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(19) {
    top: 17px;
    left: 53px;
    animation-delay: 1.9s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(21) {
    top: 18px;
    left: 39px;
    animation-delay: 2.1s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(23) {
    top: 4px;
    left: 70px;
    animation-delay: 2.3s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(25) {
    top: 7px;
    left: 46px;
    animation-delay: 2.5s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(27) {
    top: 2px;
    left: 58px;
    animation-delay: 2.7s;
    transition: 4s ease background;
    position: absolute;
}
.rain:nth-child(29) {
    top: 27px;
    left: 63px;
    animation-delay: 2.9s;
    transition: 4s ease background;
    position: absolute;
}

@keyframes drops {
    0% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
    5% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translate3d(20px, 200px, 0);
        opacity: 0;
    }
}
@keyframes snow {
    0% {
        margin-top: 0px;
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    50% {
        opacity: 1;
        margin-left: 20px;
    }
    100% {
        margin-top: 200px;
        opacity: 0;
    }
}

.base {
    width: 650px;
    height: 20px;
    left: 80px;
    background-color: var(--bush);
    border-radius: 20px;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: 4s ease background;
    position: absolute;
}
.bush-group {
    width: 100%;
    height: 100%;
    bottom: 5px;
    left: 0;
    transition: 4s ease background;
    position: absolute;
}
.bush {
    z-index: 100;
    width: 90px;
    height: 40px;
    bottom: 0px;
    left: 0;
    background-color: var(--bush);
    border-radius: 50px 50px 0 0;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(2) {
    left: 70px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(3) {
    left: 140px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(4) {
    left: 210px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(5) {
    left: 280px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(6) {
    left: 350px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(7) {
    left: 420px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(8) {
    left: 490px;
    transition: 4s ease background;
    position: absolute;
}
.bush:nth-child(9) {
    left: 560px;
    transition: 4s ease background;
    position: absolute;
}
.tree-group {
    width: 100%;
    height: 350px;
    bottom: 0px;
    left: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree {
    z-index: 1;
    bottom: 10px;
    width: 5px;
    height: 50px;
    left: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree .tree-top {
    background-color: var(--dark);
    border-radius: 100%;
    top: -30px;
    width: 40px;
    height: 40px;
    left: -20px;
    transition: 4s ease background;
    position: absolute;
}
.tree .tree-top:after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 5%;
    right: 5%;
    background-color: var(--medium);
    border-radius: 100%;
    transition: 4s ease background;
    position: absolute;
}
.tree .trunk {
    background-color: var(--trunk);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(1) .trunk,
.tree:nth-child(2) .trunk,
.tree:nth-child(3) .trunk,
.tree:nth-child(6) .trunk,
.tree:nth-child(7) .trunk,
.tree:nth-child(8) .trunk,
.tree:nth-child(11) .trunk,
.tree:nth-child(12) .trunk {
    background-color: var(--trunk2);
    transition: 4s ease background;
    position: absolute;
}
.branch {
    z-index: -1;
    height: 10px;
    width: 40px;
    border-radius: 10px;
    background-color: var(--dark);
    transform: rotate(30deg);
    transform-origin: 0% 0%;
    left: 5px;
    transition: 4s ease background;
    position: absolute;
}
.branch-two {
    top: 70px;
    height: 20px;
    width: 40px;
    border-radius: 0 0 20px 0;
    border: 7px solid var(--trunk);
    border-left: 0;
    border-top: 0;
    transition: 4s ease background;
    position: absolute;
}
.branch-two .tree-top {
    width: 50px;
    height: 20px;
    top: -10px;
    left: 20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(2) {
    left: 40px;
    height: 70px;
    width: 6px;
    z-index: 2;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(2) .tree-top {
    background-color: var(--medium);
    top: -35px;
    width: 50px;
    height: 50px;
    left: -23px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(2) .tree-top:after {
    background-color: var(--light);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(3) {
    left: 80px;
    height: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(3) .tree-top {
    background-color: var(--medium);
    top: -15px;
    width: 30px;
    height: 30px;
    left: -13px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(3) .tree-top:after {
    background-color: var(--light);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) {
    z-index: 1;
    left: 105px;
    height: 220px;
    width: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:before {
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--medium);
    border-radius: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:after {
    z-index: 1;
    content: '';
    left: 0;
    top: 0;
    width: 66%;
    height: 100%;
    background-color: var(--light);
    border-radius: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(7),
.tree:nth-child(4) .branch:nth-child(8),
.tree:nth-child(4) .branch:nth-child(9),
.tree:nth-child(4) .branch:nth-child(10),
.tree:nth-child(4) .branch:nth-child(11),
.tree:nth-child(4) .branch:nth-child(12) {
    transform: rotate(150deg);
    top: 7px;
    left: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:first-child {
    top: 0px;
    width: 40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(5):before,
.tree:nth-child(4) .branch:nth-child(6):before,
.tree:nth-child(4) .branch:nth-child(11):before,
.tree:nth-child(4) .branch:nth-child(12):before {
    width: 66%;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(5):after,
.tree:nth-child(4) .branch:nth-child(6):after,
.tree:nth-child(4) .branch:nth-child(11):after,
.tree:nth-child(4) .branch:nth-child(12):after {
    width: 33%;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(3):before,
.tree:nth-child(4) .branch:nth-child(4):before,
.tree:nth-child(4) .branch:nth-child(9):before,
.tree:nth-child(4) .branch:nth-child(10):before {
    width: 100%;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(3):after,
.tree:nth-child(4) .branch:nth-child(4):after,
.tree:nth-child(4) .branch:nth-child(9):after,
.tree:nth-child(4) .branch:nth-child(10):after {
    width: 55%;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(2) {
    top: 25px;
    width: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(3) {
    top: 50px;
    width: 60px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(4) {
    top: 75px;
    width: 70px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(5) {
    top: 100px;
    width: 80px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(6) {
    top: 125px;
    width: 90px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(7) {
    top: 8px;
    width: 40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(8) {
    top: 33px;
    width: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(9) {
    top: 58px;
    width: 60px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(10) {
    top: 83px;
    width: 70px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(11) {
    top: 108px;
    width: 80px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(4) .branch:nth-child(12) {
    top: 133px;
    width: 90px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) {
    left: 140px;
    height: 280px;
    width: 12px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .tree-top {
    z-index: 3;
    background-color: var(--medium);
    top: -25px;
    width: 120px;
    height: 40px;
    left: -55px;
    border-radius: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .tree-top:after {
    border-radius: 50px 50px 0 0px;
    background-color: var(--light);
    width: 100%;
    left: 0;
    height: 50%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .branch-two:nth-child(5) {
    top: 38px;
    height: 5px;
    width: 60px;
    border-radius: 0 0 20px 0;
    border: 8px solid var(--trunk);
    border-left: 0;
    border-top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .branch-two:nth-child(5) .tree-top {
    width: 50px;
    height: 20px;
    top: -20px;
    left: 40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .branch-two:nth-child(6) .tree-top {
    width: 50px;
    height: 15px;
    top: -10px;
    left: 20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .branch-two:nth-child(4) {
    top: 40px;
    height: 10px;
    width: 30px;
    border-radius: 0 0 0px 20px;
    border: 8px solid var(--trunk);
    border-right: 0;
    border-top: 0;
    left: -30px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .branch-two:nth-child(4) .tree-top {
    width: 40px;
    height: 15px;
    top: -10px;
    left: -25px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(5) .tree-top:nth-child(2) {
    width: 90px;
    top: -55px;
    height: 30px;
    left: -40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(6) {
    left: 180px;
    height: 100px;
    width: 8px;
    z-index: 4;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(6) .tree-top {
    background-color: var(--dark);
    top: -50px;
    width: 80px;
    height: 80px;
    left: -35px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(6) .tree-top:after {
    background-color: var(--medium);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(7) {
    left: 210px;
    height: 70px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(7) .tree-top {
    background-color: var(--medium);
    top: -20px;
    width: 40px;
    height: 40px;
    left: -20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(7) .tree-top:after {
    background-color: var(--light);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) {
    left: 250px;
    height: 120px;
    width: 12px;
    z-index: 3;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) .tree-top {
    background-color: var(--light);
    top: -60px;
    width: 130px;
    height: 65px;
    left: -60px;
    border-radius: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) .tree-top:after {
    border-radius: 50px 0 0 50px;
    background-color: var(--medium);
    width: 50%;
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) .tree-top:nth-child(2) {
    top: -95px;
    width: 90px;
    height: 55px;
    left: -40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) .tree-top:nth-child(2):after {
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) .tree-top:nth-child(3) {
    top: -120px;
    width: 46px;
    height: 40px;
    left: -18px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(8) .tree-top:nth-child(3):after {
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(9) {
    left: 310px;
    height: 60px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(9) .tree-top {
    background-color: var(--medium);
    top: -13px;
    width: 30px;
    height: 30px;
    left: -13px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(9) .tree-top:after {
    background-color: var(--light);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) {
    left: 340px;
    height: 290px;
    width: 10px;
    z-index: 2;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch {
    height: 10px;
    width: 40px;
    background-color: var(--trunk);
    left: 5px;
    top: -1px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch-in {
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: var(--trunk);
    border-radius: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7),
.tree:nth-child(10) .branch:nth-child(8),
.tree:nth-child(10) .branch:nth-child(9),
.tree:nth-child(10) .branch:nth-child(10),
.tree:nth-child(10) .branch:nth-child(11) {
    transform: rotate(150deg);
    top: 7px;
    left: 10px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(2) {
    top: 0px;
    width: 40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(3) {
    top: 40px;
    width: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(4) {
    top: 80px;
    width: 60px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(5) {
    top: 120px;
    width: 70px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(6) {
    top: 160px;
    width: 80px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) {
    top: 8px;
    width: 40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(8) {
    top: 48px;
    width: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(9) {
    top: 88px;
    width: 60px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(10) {
    top: 168px;
    width: 70px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(11) {
    top: 128px;
    width: 80px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone {
    height: 25px;
    width: 10px;
    top: 5px;
    left: 15px;
    border-radius: 0 0 10px 10px;
    background-color: var(--light);
    transform: rotate(-30deg);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(2n + 2) {
    background-color: var(--medium);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(3n + 3) {
    background-color: var(--dark);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone,
.tree:nth-child(10) .branch:nth-child(8) .pine-cone,
.tree:nth-child(10) .branch:nth-child(9) .pine-cone,
.tree:nth-child(10) .branch:nth-child(10) .pine-cone,
.tree:nth-child(10) .branch:nth-child(11) .pine-cone {
    transform: rotate(210deg);
    left: 16px;
    top: -20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(1),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(1),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(1),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(1),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(1) {
    left: 16px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(2),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(2),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(2),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(2),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(2) {
    left: 26px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(3),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(3),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(3),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(3),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(3) {
    left: 36px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(4),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(4),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(4),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(4),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(4) {
    left: 46px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(5) {
    left: 56px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(6) {
    left: 66px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(7) {
    left: 76px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(8),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(8),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(8),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(8),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(8) {
    left: 86px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(9),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(9),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(9),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(9),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(9) {
    left: 96px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(10),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(10),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(10),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(10),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(10) {
    left: 106px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(11),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(11),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(11),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(11),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(11) {
    left: 116px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(12),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(12),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(12),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(12),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(12) {
    left: 126px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(1) {
    left: 15px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(2) {
    left: 25px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(3) {
    left: 35px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(4) {
    left: 45px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(5) {
    left: 55px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(6) {
    left: 65px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(7) {
    left: 75px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(8) {
    left: 85px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(9) {
    left: 95px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(10) {
    left: 105px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(11) {
    left: 115px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .pine-cone:nth-child(12) {
    left: 125px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(2) .pine-cone:nth-child(4),
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(4),
.tree:nth-child(10) .branch:nth-child(2) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(2) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(2) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(7) .pine-cone:nth-child(7) {
    display: none;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(3) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(5),
.tree:nth-child(10) .branch:nth-child(3) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(3) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(8) .pine-cone:nth-child(7) {
    display: none;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(4) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(6),
.tree:nth-child(10) .branch:nth-child(4) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(9) .pine-cone:nth-child(7) {
    display: none;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(10) .branch:nth-child(5) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(6) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(10) .pine-cone:nth-child(7),
.tree:nth-child(10) .branch:nth-child(11) .pine-cone:nth-child(7) {
    display: none;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(11) {
    z-index: 5;
    left: 390px;
    height: 80px;
    width: 8px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(11) .tree-top {
    background-color: var(--light);
    top: -30px;
    width: 80px;
    height: 40px;
    left: -35px;
    border-radius: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(11) .tree-top:after {
    border-radius: 50px 0 0 50px;
    background-color: var(--medium);
    width: 50%;
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(11) .tree-top:nth-child(2) {
    top: -50px;
    width: 60px;
    height: 30px;
    left: -25px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(11) .tree-top:nth-child(3) {
    top: -65px;
    width: 40px;
    height: 30px;
    left: -15px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(12) {
    left: 430px;
    height: 130px;
    width: 10px;
    transition: 4s ease background;
    position: absolute;
    z-index: 4;
}
.tree:nth-child(12) .tree-top {
    background-color: var(--medium);
    top: -50px;
    width: 130px;
    height: 55px;
    left: -60px;
    border-radius: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(12) .tree-top:after {
    border-radius: 50px 0 0 50px;
    background-color: var(--dark);
    width: 50%;
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(12) .tree-top:nth-child(2) {
    top: -85px;
    width: 90px;
    height: 45px;
    left: -40px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(12) .tree-top:nth-child(3) {
    top: -110px;
    width: 50px;
    height: 40px;
    left: -20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(12) .flower:nth-child(6) {
    top: -70px;
    left: 22px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(12) .flower:nth-child(5) {
    top: -30px;
    left: -30px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) {
    z-index: 3;
    left: 480px;
    height: 70px;
    width: 12px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .tree-top {
    z-index: 3;
    background-color: var(--medium);
    top: -25px;
    width: 90px;
    height: 30px;
    left: -40px;
    border-radius: 50px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .tree-top:after {
    border-radius: 50px 50px 0 0px;
    background-color: var(--light);
    width: 100%;
    left: 0;
    height: 50%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .tree-top:nth-child(2) {
    z-index: 2;
    top: -50px;
    width: 70px;
    height: 25px;
    left: -30px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .tree-top:nth-child(3) {
    z-index: 1;
    top: -70px;
    width: 50px;
    height: 20px;
    left: -20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .branch-two:nth-child(5) {
    top: 23px;
    height: 5px;
    width: 30px;
    border-radius: 0 0 20px 0;
    border: 6px solid var(--trunk);
    border-left: 0;
    border-top: 0;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .branch-two:nth-child(5) .tree-top {
    width: 40px;
    height: 14px;
    top: -13px;
    left: 15px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .branch-two:nth-child(6) {
    top: 17px;
    height: 5px;
    width: 20px;
    border-radius: 0 0 0px 20px;
    border: 6px solid var(--trunk);
    border-right: 0;
    border-top: 0;
    left: -20px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(13) .branch-two:nth-child(6) .tree-top {
    width: 24px;
    height: 10px;
    top: -10px;
    left: -14px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(14),
.tree:nth-child(15) {
    z-index: 2;
    left: 550px;
    height: 120px;
    width: 7px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(14) .tree-top,
.tree:nth-child(15) .tree-top {
    z-index: 1;
    background-color: var(--dark);
    top: -40px;
    width: 100px;
    height: 90px;
    left: -45px;
    border-radius: 0;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(14) .tree-top:after,
.tree:nth-child(15) .tree-top:after {
    content: none;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(14) .tree-top:nth-child(2),
.tree:nth-child(15) .tree-top:nth-child(2) {
    z-index: 2;
    background-color: var(--medium);
    top: -60px;
    width: 80px;
    height: 70px;
    left: -37px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(14) .tree-top:nth-child(3),
.tree:nth-child(15) .tree-top:nth-child(3) {
    z-index: 3;
    background-color: var(--light);
    top: -75px;
    width: 60px;
    height: 50px;
    left: -27px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(15) {
    left: 600px;
    height: 80px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(15) .tree-top {
    top: -40px;
    width: 80px;
    height: 70px;
    left: -37px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(15) .tree-top:nth-child(2) {
    top: -60px;
    width: 65px;
    height: 60px;
    left: -30px;
    transition: 4s ease background;
    position: absolute;
}
.tree:nth-child(15) .tree-top:nth-child(3) {
    top: -70px;
    width: 50px;
    height: 40px;
    left: -22px;
    transition: 4s ease background;
    position: absolute;
}

.rabbit {
    left: 220px;
    top: -140px;
    width: 20px;
    height: 50px;
    position: absolute;
    z-index: 4;
}
.space-llama {
    margin-left: 48px;
    margin-bottom: 24px;
    align-self: flex-start;
    animation: bouncy-space 2s infinite linear;
}
@keyframes bouncy-space {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -2%);
    }
    100% {
        transform: translate(0, 0);
    }
}
.moon {
    background-color: #39beff;
    height: 90px;
    width: 90px;
    min-height: 90px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 165px;
    bottom: 33vh;
}
.moon-shadow {
    height: 90px;
    width: 90px;
    min-height: 90px;
    border-radius: 50%;
    border-right: 15px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 165px;
    bottom: 33vh;
}
.orbit {
    height: 170px;
    width: 170px;
    min-height: 170px;
    position: absolute;
    border-radius: 50%;
    animation: spin 10s infinite linear;
    left: 124px;
    bottom: 29vh;
}

.moon-row {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 33.33%;
}
.crater {
    width: 30%;
    height: 90%;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: flex-end;
    background-color: #1ca4f9;
}

.crater:before {
    content: '';
    height: 90%;
    width: 80%;
    border-radius: 50%;
    display: inline-block;
    background-color: #31b4ff;
}
.crater1 {
    left: 5%;
    bottom: -15%;
    position: relative;
    transform: scale(0.35);
}
.crater2 {
    left: -5%;
    bottom: -40%;
    position: relative;
    transform: scale(0.6);
}
.crater3 {
    top: -10%;
    left: -12%;
    position: relative;
    transform: scale(0.5);
}
.crater4 {
    top: -15%;
    left: -10%;
    position: relative;
    transform: scale(0.4);
}
.crater5 {
    top: -10%;
    left: 10%;
    position: relative;
    transform: scale(0.7);
}
.moon-face {
    height: 60%;
    width: 50%;
    left: -15%;
    bottom: -45%;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.moon-eye {
    background-color: #161616;
    height: 40%;
    width: 16%;
    border-radius: 50%;
}

.moon-mouth {
    height: 30%;
    width: 24%;
    border: 2px solid #161616;
    align-self: flex-end;
    border-top: none;
    border-radius: 0 0 10px 10px;
}
.blush {
    background-color: #1ca4f9;
    height: 25%;
    width: 10%;
    align-self: center;
    border-radius: 50%;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.rocket {
    background-color: #fafcf7;
    height: 16%;
    width: 8%;
    border-radius: 50% 50% 0 0;
    position: relative;
    top: 40%;
    left: -3%;
}
.rocket:before {
    content: '';
    position: absolute;
    background-color: #39beff;
    height: 40%;
    width: 240%;
    border-radius: 50% 50% 0 0;
    z-index: -1;
    right: -70%;
    bottom: 0;
}
.rocket:after {
    content: '';
    position: absolute;
    background-color: #39beff;
    height: 10%;
    width: 70%;
    border-radius: 0 0 20px 20px;
    bottom: -10%;
    left: 15%;
}
.rocket-window {
    background-color: #151845;
    height: 30%;
    width: 60%;
    border: 2px solid #b8d2ec;
    border-radius: 50%;
    position: relative;
    top: 30%;
    left: 20%;
}
