.sunglasses {
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 15;
    position: relative;
    align-items: center;
    justify-content: center;
}
.sunglasses-frame-left {
    height: 100%;
    width: 50%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 50% 0 60% 50%/30% 0 50% 50%;
}
.sunglasses-lens-left {
    left: 5%;
    width: 40%;
    height: 80%;
    z-index: 17;
    position: absolute;
    border-radius: 50% 0 60% 50%/30% 0 50% 50%;
    background: linear-gradient(
        to bottom right,
        #3860b8,
        #3860b8 15%,
        white 35%,
        white 35%,
        #3860b8 55%,
        #3860b8
    );
}
.sunglasses-bridge {
    align-self: flex-start;
    height: 60%;
    width: 2%;
    background-color: #3a3636;
}
.sunglasses-bridge-lens {
    position: absolute;
    top: 10%;
    height: 42%;
    width: 10%;
    z-index: 17;
    align-self: flex-start;
    background-color: #3860b8;
}
.sunglasses-frame-right {
    height: 100%;
    width: 50%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 0 50% 50% 60%/0 30% 50% 50%;
}
.sunglasses-lens-right {
    right: 5%;
    width: 40%;
    height: 80%;
    z-index: 17;
    position: absolute;
    background-color: #3860b8;
    border-radius: 0 50% 50% 60%/0 30% 50% 50%;
    background: linear-gradient(
        to bottom right,
        #3860b8,
        #3860b8 20%,
        white 40%,
        white 40%,
        #3860b8 60%,
        #3860b8
    );
}
