:root {
    --dark-purple: #522ed6;
    --mid-purple: #7e63e0;
    --light-purple: #937de5;
}
.llama-land {
    margin: 0;
    background: #9cd3f9;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.flex {
    display: flex;
}
.clouds-game {
    top: 0;
    width: 100%;
    height: 40%;
    overflow: hidden;
    position: absolute;
}
.big-cloud {
    height: 150px;
    width: 450px;
    bottom: 10%;
    left: 100%;
    position: absolute;
    animation: move-cloud 40s 20s infinite linear;
}
.little-cloud {
    height: 50px;
    width: 150px;
    top: 10%;
    left: 100%;
    position: absolute;
    animation: move-cloud 60s infinite linear;
}
.medium-cloud {
    height: 100px;
    width: 300px;
    top: 30%;
    left: 100%;
    position: absolute;
    animation: move-cloud 50s 40s infinite linear;
}
.medium-ball {
    height: 50%;
    width: 35%;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    background-color: #ffc9ea;
    bottom: 0;
    position: absolute;
}
.big-ball {
    height: 100%;
    width: 70%;
    left: 15%;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    background-color: #fcc0e4;
    bottom: 0;
    position: absolute;
}
.little-ball {
    height: 30%;
    width: 25%;
    right: 0;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    background-color: #ffc9e9;
    bottom: 0;
    position: absolute;
}

.alpaca__container-game {
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-end;
    transform: rotateY(180deg);
    animation: alpaca-bounce 0.65s infinite ease-in;
}

.alpaca-game {
    width: 100%;
    height: 100%;
}

.alpaca__top,
.alpaca__btm {
    height: 50%;
    width: 100%;
    position: relative;
}
.alpaca__top {
    height: 50%;
    justify-content: space-between;
}

.head {
    width: 40%;
    position: relative;
    align-items: flex-end;
}
.head__ears {
    width: 100%;
    height: 28%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
}
.head__ears div {
    background-color: var(--light-purple);
    width: 25%;
    height: 100%;
    width: 25%;
    position: relative;
}
.head__ears div::after {
    content: '';
    width: 50%;
    height: 55%;
    position: absolute;
    background-color: var(--dark-purple);
    bottom: 5%;
}
.head__ears div:first-child,
.head__ears div:first-child::after {
    border-radius: 100% 0 0 100%/50% 0 0 50%;
}
.head__ears div:first-child {
    transform-origin: 100% 100%;
    transform: rotate(0) translate(0%, 15%);
    animation: twitch-ear-left 10s 3s infinite ease-in-out;
}
.head__ears div:first-child::after {
    right: 20%;
    bottom: 20%;
}
.head__ears div:last-child,
.head__ears div:last-child::after {
    border-radius: 0 100% 100% 0/0 50% 50% 0;
}
.head__ears div:last-child {
    transform-origin: 0 100%;
    transform: rotate(0deg) translate(0%, 15%);
    animation: twitch-ear-right 10s 3.1s infinite ease-in-out;
}
.head__ears div:last-child::after {
    left: 20%;
    bottom: 20%;
}
.neck {
    width: 40%;
    background-color: var(--light-purple);
}
/* .alpaca:hover .neck {
    animation: grow-neck 3s 0s 1 ease-in-out;
} */

.neck__hair {
    height: 25%;
    width: 100%;
    bottom: 0px;
    position: absolute;
}
.hair-container {
    height: 100%;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
}
.curl {
    height: 65%;
    width: 40%;
    border-radius: 50%;
    border-bottom: 2px solid var(--dark-purple);
}
.head__face-neck {
    width: 100%;
    height: 75%;
    background-color: var(--light-purple);
    border-radius: 50% 50% 0 0/30% 30% 0 0;
    z-index: 999;
    margin-bottom: -1%;
}
.head__face-neck .face {
    position: relative;
    width: 100%;
    height: 40%;
    flex-direction: column;
    align-items: center;
    margin-top: 36%;
}
.head__face-neck .face::before {
    content: '';
    width: 70%;
    height: 100%;
    position: absolute;
    background-color: #c682e6;
    border-radius: 50%;
}
.head__face-neck .eyes,
.head__face-neck .cheeks,
.head__face-neck .nose,
.head__face-neck .mouth {
    z-index: 10;
}
.head__face-neck .eyes {
    width: 72%;
    height: 45%;
    justify-content: space-between;
    margin-top: -5%;
}
.head__face-neck .eyes div {
    width: 40%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    justify-content: center;
    align-items: center;
}
.head__face-neck .eyes div::after {
    content: '';
    width: 45%;
    height: 45%;
    background-color: #4e9cd3;
    border-radius: 50%;
    animation: blink 5s infinite ease-in-out;
}
.sunnies {
    height: 60%;
    width: 100%;
    z-index: 15;
    bottom: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
}
.duct-tape-left {
    height: 27%;
    width: 45%;
    position: absolute;
    left: 0;
    z-index: 9999999;
    border-radius: 20%;
    background-color: #3a3636;
}
.duct-tape-right {
    height: 27%;
    width: 45%;
    position: absolute;
    right: 0;
    z-index: 9999999;
    border-radius: 20%;
    background-color: #3a3636;
}
.stem-left {
    height: 27%;
    width: 11.5%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 20% 0 0 20%;
}
.stem-right {
    height: 27%;
    width: 11.5%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 0 20% 20% 0;
}
.frame-left {
    height: 100%;
    width: 50%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 50% 0 60% 50%/30% 0 50% 50%;
}
.lens-left {
    left: 5%;
    width: 40%;
    height: 80%;
    z-index: 17;
    position: absolute;
    border-radius: 50% 0 60% 50%/30% 0 50% 50%;
    background: linear-gradient(
        to bottom right,
        #3860b8,
        #3860b8 15%,
        white 35%,
        white 35%,
        #3860b8 55%,
        #3860b8
    );
}
.bridge {
    align-self: flex-start;
    height: 60%;
    width: 2%;
    background-color: #3a3636;
}
.bridge-lens {
    position: absolute;
    margin-top: 3.6%;
    height: 42%;
    width: 10%;
    z-index: 17;
    align-self: flex-start;
    background-color: #3860b8;
}
.frame-right {
    height: 100%;
    width: 50%;
    background-color: #3a3636;
    z-index: 16;
    border-radius: 0 50% 50% 60%/0 30% 50% 50%;
}
.lens-right {
    right: 5%;
    width: 40%;
    height: 80%;
    z-index: 17;
    position: absolute;
    background-color: #3860b8;
    border-radius: 0 50% 50% 60%/0 30% 50% 50%;
    background: linear-gradient(
        to bottom right,
        #3860b8,
        #3860b8 20%,
        white 40%,
        white 40%,
        #3860b8 60%,
        #3860b8
    );
}
.head__face-neck .cheeks {
    width: 90%;
    height: 25%;
    justify-content: space-between;
    margin-top: 3%;
}
.head__face-neck .cheeks div {
    width: 18%;
    height: 100%;
    background-color: #e87e70;
    border-radius: 50%;
}
.head__face-neck .snout {
    position: absolute;
    width: 77%;
    height: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: -4%;
}
.head__face-neck .snout::before {
    content: '';
    width: 70%;
    height: 100%;
    position: absolute;
    background-color: #df9cff;
    border-radius: 50%;
}
.head__face-neck .nose {
    width: 20%;
    height: 15%;
    background-color: var(--dark-purple);
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    margin-top: 5%;
}
.head__face-neck .mouth {
    width: 50%;
    height: 25%;
    background-color: var(--dark-purple);
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    margin-top: 5%;
    animation: smile 4s 0.5s infinite ease-in-out;
}

.tail-game {
    border-radius: 0 100% 100% 0/0 50% 50% 0;
    background-color: var(--light-purple);
    align-self: flex-end;
    transform-origin: 10% 90%;
    position: absolute;
    right: 1%;
    top: -15%;
    transform: rotate(0);
    animation: bounce-tail 0.65s infinite ease-in-out;
}

.body {
    background-color: var(--light-purple);
    width: 90%;
    height: 55%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 10% 50% 50%/0 20% 60% 60%;
    align-items: center;
    justify-content: flex-end;
}
.leg-fill {
    top: 0%;
    right: 4%;
    width: 25%;
    height: 30%;
    border-radius: 50%;
    border-bottom-right-radius: 100%;
    border-top-left-radius: 80%;
    border-top-right-radius: 70%;
    position: absolute;
    background-color: var(--light-purple);
}
.body_hair {
    width: 100%;
    height: 55%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 10% 50% 50%/0 20% 60% 60%;
    align-items: center;
    justify-content: flex-end;
}
.body .spots,
.body .spots::after,
.body .spots::before {
    border-radius: 50%;
}
.body .spots {
    width: 0;
    height: 0;
    position: relative;
    right: 16%;
    bottom: 13%;
    border-top: 6.5vmin solid #acdfd0;
    border-right: 2.5vmin solid transparent;
    border-left: 2.5vmin solid transparent;
}
.body .spots::before,
.body .spots::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    background-color: transparent;
    border-top: 5vmin solid #acdfd0;
    border-right: 2vmin solid transparent;
    border-left: 2vmin solid transparent;
    transform-origin: 50% 0;
}
.body .spots::before {
    left: -130%;
    transform: rotate(55deg) translate3d(0, -90%, 0);
}
.body .spots::after {
    right: -130%;
    transform: rotate(-55deg) translate3d(0, -90%, 0);
}

.legs {
    width: 100%;
    height: 100%;
    justify-content: space-between;
}
.legs__front,
.legs__back {
    width: 40%;
    height: 100%;
    position: relative;
}

.leg-front-dark {
    animation: leg-front-dark 0.65s infinite ease-in;
    height: 80% !important;
    top: 10%;
}
.leg-front-light {
    animation: leg-front-light 0.65s infinite ease-in;
    height: 80% !important;
    top: 10%;
}
.leg-back-dark {
    animation: leg-back-dark 0.65s infinite ease-in;
    height: 80% !important;
    top: 10%;
}
.leg-back-light {
    animation: leg-back-light 0.65s infinite ease-in;
    transform-origin: 0% 0%;
}
.prance-front-dark-1 {
    animation: prance-front-dark 1.2s ease-in-out;
}
.prance-front-light-1 {
    animation: prance-front-light 1.2s ease-in-out;
}
.prance-back-dark-1 {
    animation: prance-back-dark 1.2s ease-in-out;
}
.prance-back-light-1 {
    animation: prance-back-light 1.2s ease-in-out;
}
.jump-1 {
    animation: llama-jump 1.2s linear;
}
.tail-jump-1 {
    animation: tail-jump 1.2s ease-in-out;
}

.prance-front-dark-2 {
    animation: prance-front-dark 1s ease-in-out;
}
.prance-front-light-2 {
    animation: prance-front-light 1s ease-in-out;
}
.prance-back-dark-2 {
    animation: prance-back-dark 1s ease-in-out;
}
.prance-back-light-2 {
    animation: prance-back-light 1s ease-in-out;
}
.jump-2 {
    animation: llama-jump 1s linear;
}
.tail-jump-2 {
    animation: tail-jump 1s ease-in-out;
}

.prance-front-dark-3 {
    animation: prance-front-dark 0.8s ease-in-out;
}
.prance-front-light-3 {
    animation: prance-front-light 0.8s ease-in-out;
}
.prance-back-dark-3 {
    animation: prance-back-dark 0.8s ease-in-out;
}
.prance-back-light-3 {
    animation: prance-back-light 0.8s ease-in-out;
}
.jump-3 {
    animation: llama-jump 0.8s linear;
}
.tail-jump-3 {
    animation: tail-jump 0.8s ease-in-out;
}

.prance-front-dark-4 {
    animation: prance-front-dark 0.6s ease-in-out;
}
.prance-front-light-4 {
    animation: prance-front-light 0.6s ease-in-out;
}
.prance-back-dark-4 {
    animation: prance-back-dark 0.6s ease-in-out;
}
.prance-back-light-4 {
    animation: prance-back-light 0.6s ease-in-out;
}
.jump-4 {
    animation: llama-jump 0.6s linear;
}
.tail-jump-4 {
    animation: tail-jump 0.6s ease-in-out;
}

.prance-front-dark-5 {
    animation: prance-front-dark 0.4s ease-in-out;
}
.prance-front-light-5 {
    animation: prance-front-light 0.4s ease-in-out;
}
.prance-back-dark-5 {
    animation: prance-back-dark 0.4s ease-in-out;
}
.prance-back-light-5 {
    animation: prance-back-light 0.4s ease-in-out;
}
.jump-5 {
    animation: llama-jump 0.4s linear;
}
.tail-jump-5 {
    animation: tail-jump 0.4s ease-in-out;
}

.legs__front div,
.legs__back div {
    width: 55%;
    height: 90%;
    background-color: var(--light-purple);
    position: absolute;
}
.legs__front div::after,
.legs__back div::after {
    content: '';
    width: 35%;
    height: 50%;
    position: absolute;
    background-color: var(--light-purple);
    bottom: -20%;
}
.legs__front div:first-child,
.legs__front div:first-child::after,
.legs__back div:first-child,
.legs__back div:first-child::after {
    background-color: var(--mid-purple);
}
.legs__front div {
    border-radius: 0 0 100% 100%/0 0 25% 25%;
}
.legs__front div:first-child::after,
.legs__front div:last-child::after {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}
.legs__front div:last-child {
    right: 0;
}
.legs__back div {
    border-radius: 0 40% 0 100%/0 30% 0 30%;
}
.legs__back div:last-child,
.legs__back div:last-child::after,
.legs__back div:first-child::after {
    right: 0;
}

.flower {
    position: absolute;
    bottom: 9%;
    z-index: 11;
    height: 40px;
    width: 16px;
}
.flower-top {
    transform: scale(0.5, 0.5) rotate(-20deg) translate(0, 0);
    animation: flower-bounce 2s infinite ease-in-out;
}
.flower-center {
    height: 12px;
    width: 12px;
    position: absolute;
    border-radius: 6px;
    top: 13px;
    left: 2px;
    background-color: #f6ad55;
    z-index: 12;
}
.pedal {
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: white;
}
.stem {
    position: absolute;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    width: 18px;
    height: 28px;
    border: solid 2px;
    z-index: -1;
    border-color: transparent green transparent transparent;
    border-radius: 70% 30% 12% 88% / 45% 50% 50% 55%;
    animation: stem-bounce 2s infinite ease-in-out;
}
.shrubberies {
    display: flex;
    height: 10%;
    width: 100%;
    position: absolute;
    bottom: 8%;
    z-index: 999;
}
.grass-game {
    width: 100%;
    height: 10%;
    background-color: #6fc66c;
    position: absolute;
    bottom: 0px;
    z-index: 10;
    transform: translate(0, 0);
}
.tuft {
    width: 10px;
    height: 17px;
    position: absolute;
    bottom: 9%;
    z-index: 11;
    display: flex;
    justify-content: space-between;
}
.blade {
    width: 2px;
    height: 100%;
    background-color: green;
}
.hay-container {
    width: 2500%;
    position: absolute;
    bottom: 10%;
    left: 100%;
    display: flex;
}
.hay-compartment {
    height: 100%;
    width: 4%;
}
.hay-bail {
    background-color: #f6ad55;
    z-index: -999;
    box-shadow: 0px 0px 10px 5px rgba(246, 173, 85, 1);
}
.big-swirl {
    height: 50%;
    width: 100%;
    position: relative;
    border-radius: 1000px 1000px 0 0;
    border: 16px solid #7b341e;
    border-bottom: transparent;
    border-right: transparent;
}
.medium-swirl {
    height: 40%;
    width: 80%;
    position: relative;
    border-radius: 0 0 1000px 1000px;
    border: 16px solid #7b341e;
    border-top: transparent;
}
.little-swirl {
    height: 30%;
    width: 60%;
    position: relative;
    top: -70%;
    left: 20%;
    border-radius: 1000px 1000px 0 0;
    border: 16px solid #7b341e;
    border-bottom: transparent;
}
.final-swirl {
    height: 20%;
    width: 40%;
    position: relative;
    top: -70%;
    left: 20%;
    border-radius: 0 0 1000px 1000px;
    border: 16px solid #7b341e;
    border-top: transparent;
    border-right: transparent;
}

.level-1 {
    animation: move-hay 60s 5s linear;
}
.level-2 {
    animation: move-hay 50s linear;
}
.level-3 {
    animation: move-hay 40s linear;
}
.level-4 {
    animation: move-hay 30s linear;
}
.level-5 {
    animation: move-hay 20s infinite linear;
}

.hay-level-1 {
    animation: roll-hay 4s infinite linear;
}
.hay-level-2 {
    animation: roll-hay 3.5s infinite linear;
}
.hay-level-3 {
    animation: roll-hay 2.5s infinite linear;
}
.hay-level-4 {
    animation: roll-hay 1.5s infinite linear;
}
.hay-level-5 {
    animation: roll-hay 0.5s infinite linear;
}

.shrubs-level-1 {
    animation: move-shrubs 3s infinite linear;
}
.shrubs-level-2 {
    animation: move-shrubs 2.5s infinite linear;
}
.shrubs-level-3 {
    animation: move-shrubs 2s infinite linear;
}
.shrubs-level-4 {
    animation: move-shrubs 1.5s infinite linear;
}
.shrubs-level-5 {
    animation: move-shrubs 1s infinite linear;
}

.game-container {
    width: 100%;
    height: 100%;
    padding: 60px;
    display: flex;
    font-size: 70px;
    z-index: 999999999;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    font-family: 'Press Start 2P';
    color: var(--chakra-colors-orange-100);
    background-color: rgba(0, 33, 87, 1);
}
.game-button {
    padding: 20px;
    cursor: pointer;
    font-size: 30px;
    font-family: 'Press Start 2P';
    color: var(--chakra-colors-orange-100);
}
.game-button:hover {
    color: black;
    background-color: var(--chakra-colors-orange-100);
}

#message-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 24px;
    z-index: 99;
    font-size: 50px;
    margin-bottom: 15%;
    position: absolute;
    text-align: center;
    line-height: 100px;
    align-items: center;
    justify-content: center;
    font-family: 'Press Start 2P';
    color: var(--chakra-colors-orange-100);
}

@keyframes move-hay {
    0% {
        left: 100%;
    }
    100% {
        left: -2600%;
    }
}

@keyframes roll-hay {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-90deg);
    }
    50% {
        transform: rotate(-180deg);
    }
    75% {
        transform: rotate(-270deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes flower-bounce {
    0% {
        transform: scale(0.5, 0.5) rotate(-20deg) translate(0, 0);
    }
    50% {
        transform: scale(0.5, 0.5) rotate(-30deg) translate(-1px, 10px);
    }
    100% {
        transform: scale(0.5, 0.5) rotate(-20deg) translate(0, 0);
    }
}
@keyframes stem-bounce {
    0% {
        border-radius: 70% 30% 12% 88% / 45% 50% 50% 55%;
    }
    50% {
        border-radius: 0% 100% 29% 71% / 45% 50% 50% 55%;
        transform: translate(4px, 0);
    }
    100% {
        border-radius: 70% 30% 12% 88% / 45% 50% 50% 55%;
    }
}
@keyframes move-clouds {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-100%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes grow-neck {
    0% {
        height: 0px;
    }
    50% {
        height: calc(100vh * 0.4);
    }
    100% {
        height: 0px;
    }
}

@keyframes alpaca-bounce {
    0% {
        bottom: 5%;
    }
    50% {
        bottom: 0%;
    }
    100% {
        bottom: 5%;
    }
}

@keyframes leg-front-dark {
    0% {
        transform: rotate(0) translate(0, 0);
    }
    25% {
        transform: rotate(20deg) translate(10%, 0);
    }
    50% {
        transform: rotate(0deg) translate(100%, 0);
    }
    75% {
        transform: rotate(-20deg) translate(100%, 0);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes prance-front-dark {
    5% {
        transform: rotate(-40deg) translate(110%, 0);
    }
    75% {
        transform: rotate(-40deg) translate(110%, 0);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes leg-front-light {
    0% {
        transform: rotate(0) translate(0, 0);
    }
    25% {
        transform: rotate(20deg) translate(-80%, 0);
    }
    50% {
        transform: rotate(0deg) translate(50%, 0);
    }
    75% {
        transform: rotate(-20deg) translate(50%, 0);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes prance-front-light {
    5% {
        transform: rotate(-40deg) translate(110%, 0);
    }
    75% {
        transform: rotate(-40deg) translate(110%, 0);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes leg-back-dark {
    0% {
        transform: rotate(0) translate(0, 0);
    }
    25% {
        transform: rotate(20deg) translate(10%, 0);
    }
    50% {
        transform: rotate(0deg) translate(100%, 0);
    }
    75% {
        transform: rotate(-20deg) translate(100%, 0);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes prance-back-dark {
    5% {
        transform: rotate(-40deg) translate(100%, 0);
    }
    75% {
        transform: rotate(-40deg) translate(100%, 0);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes leg-back-light {
    0% {
        transform: rotate(0) translate(0, 0);
    }
    25% {
        transform: rotate(20deg) translate(10%, 0);
    }
    50% {
        transform: rotate(0deg) translate(30%, 0);
    }
    75% {
        transform: rotate(-20deg) translate(20%, 2%);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes prance-back-light {
    5% {
        transform: rotate(-40deg) translate(-10%, 2%);
    }
    75% {
        transform: rotate(-40deg) translate(-10%, 2%);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

@keyframes bounce-tail {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(10%, 30%) rotate(20deg);
    }
    50% {
        transform: translate(0, 0) rotate(0deg);
    }
    75% {
        transform: translate(-10%, -30%) rotate(-20deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

@keyframes tail-jump {
    5% {
        transform: translate(-10%, -30%) rotate(-20deg);
    }
    75% {
        transform: translate(-10%, -30%) rotate(-20deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

@keyframes blink {
    0% {
        height: 45%;
    }
    2% {
        height: 0%;
    }
    4% {
        height: 45%;
    }
    6% {
        height: 0%;
    }
    8% {
        height: 45%;
    }
    100% {
        height: 45%;
    }
}
@keyframes flick-tail {
    0% {
        transform: rotate(0);
    }
    2% {
        transform: rotate(45deg);
    }
    6% {
        transform: rotate(-10deg);
    }
    9% {
        transform: rotate(45deg);
    }
    12% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}
@keyframes twitch-ear-left {
    0% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    5% {
        transform: rotate(-45deg) translate(0%, 15%);
    }
    8% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    100% {
        transform: rotate(0deg) translate(0%, 15%);
    }
}
@keyframes twitch-ear-right {
    0% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    5% {
        transform: rotate(45deg) translate(0%, 15%);
    }
    8% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    51% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    53% {
        transform: rotate(45deg) translate(0%, 15%);
    }
    55% {
        transform: rotate(0deg) translate(0%, 15%);
    }
    100% {
        transform: rotate(0deg) translate(0%, 15%);
    }
}
@keyframes smile {
    0% {
        height: 25%;
    }
    70% {
        height: 25%;
    }
    75% {
        height: 40%;
    }
    80% {
        height: 40%;
    }
    85% {
        height: 5%;
    }
    90% {
        height: 20%;
    }
    95% {
        height: 5%;
    }
    100% {
        height: 25%;
    }
}
@keyframes move-cloud {
    0% {
        left: 100%;
    }
    100% {
        left: -30%;
    }
}
@keyframes move-shrubs {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-45%, 0);
    }
}

@keyframes llama-jump {
    0% {
        bottom: 5%;
    }
    25% {
        bottom: 125%;
    }
    50% {
        bottom: 145%;
    }
    75% {
        bottom: 125%;
    }
    100% {
        bottom: 5%;
    }
}
