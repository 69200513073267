.tree {
    left: 250px;
    height: 120px;
    width: 12px;
    z-index: 3;
    transition: 4s ease background;
    position: absolute;
    background-color: brown;
}

.tree-top {
    top: -95px;
    width: 90px;
    height: 55px;
    left: -40px;
    transition: 4s ease background;
    position: absolute;
    background-color: green;
}

.tree-top::after {
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
    border-radius: 50px 0 0 50px;
    background-color: green;
}
.tree-top-1 {
    top: -120px;
    width: 46px;
    height: 40px;
    left: -18px;
    transition: 4s ease background;
    position: absolute;
    background-color: green;
    top: -60px;
    width: 130px;
    height: 65px;
    left: -60px;
    border-radius: 50px;
    transition: 4s ease background;
    position: absolute;
}

.tree-top-1::after {
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
    border-radius: 50px 0 0 50px;
    background-color: green;
    width: 50%;
    left: 0;
    height: 100%;
    top: 0;
    transition: 4s ease background;
    position: absolute;
}
